.floating-button{
    position: fixed;
    z-index: 10;
}

.floating-button-right{
    bottom: 5vh;
    right: 5vw;
}

.floating-button-center{
    bottom: 5vh;
    right: 50vw;
    transform: translateX(50%);
}

.floating-button-left{
    bottom: 5vh;
    left: 5vw;
}

.footer{
    min-height: 20vh;
}

.header{
    text-align: center;
}

.chart-area{
    height: 200px;
    min-height: 20vh;
}
